import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Container, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SquareIcon from "@mui/icons-material/Rectangle"; // Example square icon
import CustomizedProgressBars from "../CustomizedProgressBars";
import { firebaseConfig } from "../../../Utility/Helper";


const ImageContainer = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  // margin: 2,
  width: "100%",  // Ensures the container takes the full width of the window
  height: "auto",  // Height will adjust based on the image's aspect ratio
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const BannerImage = styled("img")(({ theme }) => ({
  width: "100%",  // Ensures the image takes up the full width of the container
  height: "auto",  // Maintain aspect ratio as the width changes
  // [theme.breakpoints.down("md")]: {
  //   width: "74%",  // Adjust image width for medium screens
  // },
  // [theme.breakpoints.down("sm")]: {
  //   width: "50%",  // Adjust image width for small screens
  // },
  // [theme.breakpoints.down("xs")]: {
  //   width: "40%",  // Adjust image width for extra-small screens
  // },
}));


const SquareIconWrapper = styled(IconButton)(({ theme }) => ({
  width: "120px",
  height: "120px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // backgroundColor: "#FFFFFF", // White background
  // color: "#000000", // Black icon color for visibility, adjust as needed
  "& svg": {
    fontSize: "2rem", // Adjust icon size
  },
  [theme.breakpoints.down("md")]: {
    width: "120px",
    height: "120px",
    "& svg": {
      fontSize: "1.5rem",
    },
  },
  [theme.breakpoints.down("sm")]: {
    width: "80px",
    height: "80px",
    "& svg": {
      fontSize: "1rem",
    },
  },
  [theme.breakpoints.down("xs")]: {
    width: "60px",
    height: "60px",
    "& svg": {
      fontSize: "0.8rem",
    },
  },
}));

const LogoImage = styled("img")(({ theme }) => ({
  width: "100%", // Make the image take up the full size of its container
  height: "100%",
  objectFit: "contain", // Ensure the image scales correctly
}));


const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

const Banner = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [urlValid, setUrlValid] = useState(false);
  const [loading, setLoading] = useState(true);

  const validationURL = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/application/get-limited-contest-data/${id}`
      );
      if (response.ok) {
        setUrlValid(true);

        const data1 = await response.json();

        setData(data1);

        // Fetch the image URL from Firebase Storage
        if (
          data1.contestBanner &&
          data1.contestBanner[0] &&
          data1.contestBanner[0].uploadedFileName
        ) {
          // Fetch the image URL from Firebase Storage
          const fileRef = ref(
            storage,
            `/contest-banner/${data1.contestBanner[0].uploadedFileName}`
          );
          try {
            const downloadURL = await getDownloadURL(fileRef);
            setImageUrl(downloadURL);
          } catch (error) {
            console.error("Error fetching image URL:", error);
            // Handle the error gracefully, e.g., by setting a default image URL
            // setImageUrl("/purpletree.png");
          }
        } else {
          // Handle the case where contest banner file name is not present in the response
          console.error("Contest banner file name not found in response data");
          // Set a default image URL or perform other necessary actions
          // setImageUrl("/purpletree.png");
        }
      } else {
        setUrlValid(false);

        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      
      toast.error("Please Check The URL, It`s Not a Valid Url", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
        hideProgressBar: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    validationURL();
  }, [id]);

  // if (loading){
  //   <CustomizedProgressBars/>
  // }
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
            {
              imageUrl &&
        <AppBar position="static" sx={{ backgroundColor: "#FFFFFF" }}>
          <Toolbar style={{padding:0}}>
            {/* <SquareIconWrapper sx={{ ml: 2 }}>
              <LogoImage src="/iimcip-logo.png" alt="Logo" />
            </SquareIconWrapper> */}
            <ImageContainer >
              <BannerImage 
              // sx={{mx:5}}
               src={imageUrl} alt="Banner" />
            </ImageContainer>
          </Toolbar>
        </AppBar>
            }
      </Box>
    </>
  );
};

export default Banner;
