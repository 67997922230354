// Initialize Firebase Storage
export  const firebaseConfig = {

    apiKey: "AIzaSyCmaHA3w-t4Z-Dek_j8xVyRDVdPvbxRLic",
  
    authDomain: "iimcip-contest-portal.firebaseapp.com",
  
    projectId: "iimcip-contest-portal",
  
    storageBucket: "iimcip-contest-portal.appspot.com",
  
    messagingSenderId: "373688280942",
  
    appId: "1:373688280942:web:77aafd9bbce9d0ccefc37f",
  
    measurementId: "G-NZ571S9QBX"
  
  };
  