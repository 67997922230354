import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import VerticalLinearStepper from "../../../VerticalLinearStepper";
import { toast } from "react-toastify";
import CustomizedProgressBars from "../CustomizedProgressBars";
import PageNotFound from "../404";
import Navbar from "../Navbar";
import Footer from "../Footer";

const Home = () => {
  const navigate = useNavigate();
  const { contestId } = useParams();
  const [loading, setLoading] = useState(true);
  const [urlValid, setUrlValid] = useState(false);

  const validationURL = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/application/get-limited-contest-data/${contestId}`,
        {
          headers: {
            'Cache-Control': 'no-cache'
          },
        }
      );
      console.log(response.ok,"response")
      if (response.ok) {
        setUrlValid(true);
      } else {
        setUrlValid(false);
        localStorage.clear();
        navigate(`/login/${contestId}`);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Please Check The URL, It's Not a Valid Url", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
        hideProgressBar: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    validationURL();
  }, [contestId, navigate]);

  

  if (loading) {
    return <CustomizedProgressBars />;
  }

  return (
    <>
      {urlValid ? (
        <>
          <Navbar
           
          />
          <Grid container spacing={2} alignItems="center">
        <Grid item mt={1} xs={11} container justifyContent="flex-end">
          {/* Removed buttons here */}
        </Grid>
        <Grid item xs={12}>
          <VerticalLinearStepper id={contestId} />
        </Grid>
      </Grid>
        </>
      ) : (
        <PageNotFound />
      )}
      {/* <Footer/> */}
    </>
  );
};

export default Home;
